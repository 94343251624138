.Maps {
  background-color: #f5f5f5;

  .maps-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: $section-width;
    margin: auto;

    .maps-title {
      display: flex;
      padding-bottom: 32px;
      flex-direction: column;
      align-items: center;
    }

    .buttons {
      padding-top: 20px;
      display: grid;
      row-gap: 10px;
      grid-template-columns: auto;
      padding-bottom: 20px;
      width: 60vw;
    }
  }
}

@include media-breakpoint-up(md) {
  .Maps {
    .buttons {
      flex-direction: row;

      .button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
