.Header {
  &.Home {
    background-color: $color-aqua;
  }

  &.Section {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .navbar-nav {
    padding-top: 0.5rem;
  }

  .logo-compass {
    display: block;
    width: 2.5rem;
    height: auto;
  }

  .logo-with-icons {
    display: none;
    max-width: 14rem;
    height: auto;
  }
}

@include media-breakpoint-up(sm) {
  .Header {
    .navbar-nav {
      padding-top: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .Header {
    .logo-compass {
      display: none;
    }

    .logo-with-icons {
      display: block;
    }
  }
}
