.Design {
  background-color: #f5f5f5;

  .design-container {
    max-width: $section-width;
    margin: auto;

    .design-header {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .Design {
    .design-container {
      .design-header {
        margin-bottom: 0;
      }
    }
  }
}
