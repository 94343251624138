.RegistrationTop {
  .registration-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $section-width;
    margin: auto;

    &:not(:last-child) {
      padding-bottom: 32px;
    }

    &.registration-event-info {
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
    }

    .registration-title {
      padding-bottom: 32px;
    }

    .registration-centered-title {
      flex-direction: column;
      align-items: center;
    }

    .left-column,
    .right-column {
      &.text {
        padding-top: 32px;
      }
    }

    ul {
      &.no-style {
        list-style-type: none;
        margin-left: -40px;
      }

      &.no-margin {
        margin-left: -24px;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .RegistrationTop {
    .registration-content {
      justify-content: center;

      &:not(:last-child) {
        padding-bottom: 64px;
      }

      &.registration-event-info {
        flex-direction: row;
      }

      .left-column,
      .right-column {
        width: 46%;

        &.text {
          padding-top: 0px;
        }
      }
    }
  }
}
