.Footer {
  padding: 48px 0;
  color: $color-white;
  background-color: #448494;

  .footer-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    max-width: $section-width;
    margin: auto;

    .footer-item {
      text-align: center;

      &:not(:last-child) {
        padding-bottom: 2.5rem;
      }

      &.logo {
        display: none;
      }

      &.email {
        p:last-child {
          margin-bottom: 0;
        }
      }

      .logo-with-icons {
        width: 14rem;
        height: auto;
      }

      .social-media-list {
        display: flex;
        justify-content: center;

        .social-media-link {
          margin: 0 8px;
        }
      }

      .footer-note {
        display: flex;
        justify-content: center;
        padding-top: 24px;
      }

      a {
        color: $color-white;
        text-decoration: none;
      }
    }
  }

  .FaHeart {
    fill: red;
    margin-bottom: 2px;
  }
}

@include media-breakpoint-up(sm) {
  .Footer {
    .footer-inner {
      flex-direction: row;
      padding: 0 24px;

      .footer-item {
        padding-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .Footer {
    .footer-inner {
      .footer-item {
        &.logo {
          display: block;
        }
      }
    }
  }
}
