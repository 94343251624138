// Resources Component Styles
@import "components/Footer.scss";
@import "components/Resources/Ceremonies.scss";
@import "components/Resources/Workshops.scss";
@import "components/Resources/Maps.scss";

.Resources {
  .resources-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: $section-width;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .resources-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 30px;
  }

  .banner {
    width: 100vw;
    height: auto;
  }

  .full-length-button {
    padding-top: 20px;
    display: grid;
    row-gap: 10px;
    grid-template-columns: auto;
    padding-bottom: 20px;
    width: 100%;
  }
}
