.FAQ {
  background-color: white;

  .faq-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: $section-width;
    margin: auto;

    &:not(:last-child) {
      padding-bottom: 32px;
    }

    .faq-column-container {
      flex-direction: row;
      justify-content: space-between;
    }

    .faq-centered-title {
      flex-direction: column;
      align-items: center;
    }

    .left-column,
    .right-column {
      &.text {
        padding-top: 32px;
      }
    }
  }

  .faq-student,
  .faq-teacher,
  .faq-parent {
    padding-bottom: 32px;
  }
}

@include media-breakpoint-up(md) {
  .FAQ {
    .faq-content {
      justify-content: center;

      &:not(:last-child) {
        padding-bottom: 64px;
      }

      &.faq-column-container {
        flex-direction: row;
      }

      .left-column,
      .right-column {
        width: 46%;

        &.text {
          padding-top: 0px;
        }
      }
    }
  }
}
