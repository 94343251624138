.Alerts {
  background-color: #d1ecf1;
  padding-top: 1em !important;
  padding-bottom: 1em !important;

  .alert {
    border: 0 solid transparent;
    margin-bottom: 0;
  }

  .alerts-container {
    max-width: $section-width;
    margin: auto;
  }

  .buttons {
    padding-top: 10px;
    display: flex;
    flex-direction: row;

    .button {
      padding-top: 10px;
      padding-right: 10px;
      padding-bottom: 10px;
    }
  }

  .MdKeyboardArrowRight {
    fill: $color-muted-blue;
    border: 2px solid #e2e2e2;
    border-radius: 50%;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
    -moz-transition: all 0.3s ease-in-out; /** Firefox **/
    -o-transition: all 0.3s ease-in-out; /** Opera **/
  }
}
