.ImportantLinks {
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding-bottom: 32px;

  .links-title {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .links-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
  }

  .buttons {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    .button {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

@include media-breakpoint-up(md) {
  .ImportantLinks {
    .buttons {
      flex-direction: row;

      .button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
