@import url("https://fonts.googleapis.com/css?family=Poppins&display=swap");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;

  .in-text-link {
    color: #448494;
  }
}
