.LinkWithArrow {
  display: flex;
  flex-direction: row;
  align-items: center;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;

      .MdKeyboardArrowRight {
        fill: white;
        background-color: $color-muted-blue;
        border: 2px solid $color-muted-blue;
      }
    }

    .link-text {
      margin-bottom: 0;
      margin-right: 0.5rem;
      color: $color-muted-blue;
    }
  }

  .MdKeyboardArrowRight {
    fill: $color-muted-blue;
    border: 2px solid #e2e2e2;
    border-radius: 50%;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
    -moz-transition: all 0.3s ease-in-out; /** Firefox **/
    -o-transition: all 0.3s ease-in-out; /** Opera **/
  }
}
