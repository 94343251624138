.About {
  .about-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: $section-width;
    margin: auto;

    &:not(:last-child) {
      padding-bottom: 32px;
    }

    &.about-org {
      flex-direction: column-reverse;
    }

    &.about-team {
      flex-direction: column;
    }

    .left-column,
    .right-column {
      &.text {
        padding-top: 32px;
      }
    }

    .founding-story,
    .current-team {
      a {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-decoration: none;
        color: $color-muted-blue;

        &:hover {
          .MdKeyboardArrowRight {
            fill: white;
            background-color: $color-muted-blue;
            border: 2px solid $color-muted-blue;
          }
        }

        p {
          margin-bottom: 0;
          margin-right: 0.5rem;
        }
      }
    }

    img {
      max-width: 100%;
      max-height: 100vh;
      height: auto;
    }
  }

  .MdKeyboardArrowRight {
    fill: $color-muted-blue;
    border: 2px solid #e2e2e2;
    border-radius: 50%;
    width: 1.5rem;
    min-width: 1.5rem;
    height: 1.5rem;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out; /** Chrome & Safari **/
    -moz-transition: all 0.3s ease-in-out; /** Firefox **/
    -o-transition: all 0.3s ease-in-out; /** Opera **/
  }
}

@include media-breakpoint-up(md) {
  .About {
    .about-content {
      justify-content: space-between;

      &:not(:last-child) {
        padding-bottom: 64px;
      }

      &.about-org,
      &.about-team {
        flex-direction: row;
      }

      .left-column,
      .right-column {
        width: 46%;

        &.text {
          padding-top: 0px;
        }
      }
    }
  }
}
