.RegistrationAlert {
  background-color: $color-muted-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  margin: auto;
  padding-top: 32px;
  padding-bottom: 32px;

  h3,
  h5 {
    color: white;
    padding-right: 10px;
    padding-left: 10px;
  }

  .buttons {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .notes {
    padding-top: 10px;
  }
}

@include media-breakpoint-up(md) {
  .RegistrationAlert {
    .buttons {
      flex-direction: row;

      .button {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
}
